.header {
  margin-bottom: 150px;
  @media(max-width: 1650px)
  {
    margin-bottom: 8.5vw;
  }
  
  @media(max-width: 1340px)
  {
    margin-bottom: 9.5vw;
  }
  @media(max-width: 1230px)
  {
    margin-bottom: 10vw;
  }
  &-desktop {
    width: 100%;
    background: $white;
    z-index: 12;
    &-burger {
      width: 55px;
      height: 55px;
      margin-left: 24px;
      border-radius: 50%;
      background: $red2;
      display: none;
      justify-content: center;
      align-items: center;
      &__icon {
        height: 14px;
        width: 20px;
        display: flex;
        flex-direction: column;
      }
      &__line {
        &:not(:first-child) {
          margin-top: 4px;
        }
        width: 100%;
        height: 2px;
        background: $white;
        border-radius: 2px;
      }
      $hdb: &;
      &.active {
        #{$hdb} {
          &__line {
            &:first-child {
              animation: HDBurger1 .6s ease-in forwards;
            }
            &:nth-child(2) {
              animation: HDBurger2 .6s ease-in forwards, HDBurger2Opacity .6s ease-in forwards;
            }
            &:last-child {
              animation: HDBurger3 .6s ease-in forwards;
            }

          }
          @keyframes HDBurger1 {
            from {
              transform: translateY(0);
            }
            50% {
              transform: translateY(6px) rotate(0deg);
            }
            60% {
              transform: translateY(6px) rotate(45deg);
            }
            to {
              transform: translateY(6px) rotate(405deg);
            }
          }
          @keyframes HDBurger2Opacity {
            from,70% {
              opacity: 1;
            }
            71%,to {
              opacity: 0;
            }
          }
          @keyframes HDBurger2 {
            from,60% {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
          @keyframes HDBurger3 {
            from {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-6px) rotate(0deg);
            }
            60% {
              transform: translateY(-6px) rotate(-45deg);
            }
            to {
              transform: translateY(-6px) rotate(315deg);
            }
          }
        }
      }
    }
    &-top {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
    }
    &__left, &__right {
      display: flex;
      align-items: center;
      position: relative;
    }
    &-logo {
      &__svg {
        width: calc_fluid(213, 319, 1170, 1920);
        height: calc_fluid(20, 31, 1170, 1920);
      }
      &__text {
        @include _1600 {
          @include font(16,20);
          @include _1320 {
            @include font(13,18);
          }
        }
        @include font(20, 27, null, $inactiveText);
      }
    }
    &-domains {
      margin-left: calc_fluid(77, 116, 1280, 1920);
      display: flex;
      align-items: center;
      &__wrapper {
        display: flex;
        align-items: center;
        .header-desktop-menu-link__icon{
          fill:black;
        }
        &:hover{
          .header-desktop-menu-link__icon{
            fill: $red2;
          }
        }
      }
      &__svg {
        width: 24px;
        height: 24px;
        @include _1600 {
          width: 16px;
          height: 16px;
        }
        fill: $inactiveRed;
      }
      &__domain {
        margin-left: 10px;
        @include font(20,30);
        color: $inactiveRed;
        border-bottom: 1px dashed $inactiveRed;
      }
      $hdd: &;
      &:hover {
        #{$hdd} {
          &__svg {
            fill: $red2;
          }
          &__domain {
            color: $red2;
            border-bottom: 1px dashed $red2;
          }
          
        }
      }
    }
    &-phone {
      margin-left: 13px;
      &__icon {
        height: 24px;
        width: 24px;
        fill: $text;
        margin-right: 10px;
        @include _1600 {
          height: 16px;
          width: 16px;
          margin-right: 7px;
        }
      }
      display:flex;
      align-items: center;
      @include font(22,30,bold);
      @include _1600 {
        @include font(18,24,null);
        @include _1320 {
          @include font(14,20,null);
        }

      }
      $hdp: &;
      &:hover {
        #{$hdp}__icon {
          fill: $red2;
        }
        color: $red2;
      }
    }
    &__callback {
      width: 271px;
      height: 68px;
      margin-left: 23px;

      background: #5A4C66;
      color: #fff;
      @include font_btn(20,30,700);
      @include _1600 {
        width: 240px;
        height: #{calc_static(271,240,68)}px;
        @include font_btn(18,24,700);
        @include _1320 {
          width: 220px;
          height: #{calc_static(271,220,68)}px;
          @include font_btn(16,20,700);
        }
      }

    }
    &-phone, &__callback {
      opacity: 1;
      transition: opacity .3s;
      &.hidden {
        opacity: 0;
      }
    }
    &-search {
      $hds: &;
      &-wrapper {
        position: absolute;
        opacity: 1 !important;
        top: 0;
        height: 100%;
        padding-left: 40px;
        right: -40vw;
        display: flex;
        z-index: 13;
        transition: right .3s ease-in-out;
        align-items: center;
        &.active {
          right: 0;
        }
        &.hidden {
          display: none;
        }
      }
      position: relative;
      overflow: hidden;
      height: 53px;
      width: 431px;
      border: 1px solid $inactiveRed;
      border-radius: 180px;
      background: #fff;
      &-btn {
        cursor: pointer;
        margin-left: 17px;
        padding: 14px;
        &__icon {
          height: 34px;
          width: 34px;
        }
        @include _1600 {
          margin-left: 15.44px;
          &__icon {
            width: 16px;
            height: 16px;
          }
        }
      }
      &-button {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__icon {
          width: 24px;
          height: 24px;
          fill: $inactiveRed;
        }
        $hsb: &;
        &:focus {
          #{$hsb}__icon {
            fill: $red2;
          }
        }
      }
      &__input {
        width: 100%;
        height: 100%;
        border: none;
        appearance: none;
        padding: 0 40px 0 70px;
        font-size: 18px;
        line-height: 24px;
        color: $blk;
      }
    }
    &-menu {
      background: $red2;
      height: calc_fluid(37.75, 56, 1280, 1920);
      width: 100%;
      transition: all .3s ease;
      &__container {
        display: flex;
        justify-content: space-between;
      }
      &-link {
        position: relative;
        height: 100%;
        align-items: center;
        display: flex;
        @include font_btn(20,30,700,$white);
        &-submenu {
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 12;
          background: #fff;
          box-shadow: 0 9px 28px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          position: absolute;
          padding: 20px;
          display: none;
          &__item {
            font-size: 16px;
            line-height: 24px;
            color: $blk;
            display: block;
            padding: 6px 0;
            width: 100%;
            font-weight: normal;
            &:visited {
              color: $blk;
            }
            &:hover {
              color: $red;
            }
          }
        }
        &__icon {
          height: 18px;
          width: 18px;
          margin-left: 11px;
          fill: $white;
        }
        $hdml: &;
        &:hover {
          #{$hdml} {
            &-submenu {
              display: block;
            }
          }
        }
      }
      @include _1600 {
        &-link {
          @include font_btn(18,24,700,$white);
        }
        &__icon {
          margin-left: 7px;
          height: 16px;
          width: 16px;
        }
        @include _1320 {
          height: 37.75px;
          &-link {
            @include font_btn(14,20,700,$white);
          }
          &__icon {
            margin-left: 5px;
            height: 12px;
            width: 12px;
          }
        }
      }
    }
    &-sticky {
      &-menu {
        width: 536px;
        position: fixed;
        background: $white;
        height: calc(100vh - 100px);
        right: 0;
        z-index: 9;
        display: none;
        top: 100px;
        @include _1600 {
          height: calc(100vh - 92px);
          top: 92px;
          @include _1320 {
            top: 87px;
            height: calc(100vh - 87px);
          }
        }
        &.active {
          display: block;
        }
        &-item {
          display: flex;
          border-top: 1px solid $borderCD;
          &:last-child {
            border-bottom: 1px solid $borderCD;
          }
          &__link {
            display: flex;
            width: fit-content;
            padding: 20px 35px;
            @include font(20,30,700);

          }
          &__subitems_btn {
            flex: 1;
            padding: 0 35px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          &__icon {
            height: 18px;
            width: 18px;
            transform: rotate(-90deg);
          }
          &-children {
            position:fixed;
            z-index:10;
            right: -200%;
            top: 100px;
            background:#fff;
            height:calc(100vh - 100px);
            width: 536px;
            overflow-y: scroll;
            transition: right .3s ease-in-out;
            padding-bottom: 12px;
            &-back {
              display: flex;
              padding: 15px;
              align-items: center;
              height:54px;
              border-top: 1px solid $borderCD;
              border-bottom: 1px solid $borderCD;
              background: $inactiveRed;
              &__text {
                @include font(16,20,700,$white);
              }
            }
            &-item {
              padding: 15px;
              @include font(18,26,700);
              border-bottom: 1px solid $borderCD;
              display: block;
            }
            @include _1600 {
              height: calc(100vh - 92px);
              top: 92px;
              @include _1320 {
                top: 87px;
                height: calc(100vh - 87px);
              }
            }
          }
          &.active + &-children {
            right: 0;
          }
        }
      }
    }
    $hd: &;
    position: fixed;
    top: 0;
    left: 0;
    &.sticky {
      #{$hd} {
        &-menu {
          // display: none;
          height: 0;
          overflow: hidden;
        }
        &-burger {
          display: flex;
        }
        &-top {
          padding: 16px 0;
        }
        &-search {
          &-btn {
            display: none;
          }
        }
      }
    }
  }
  position: relative;
  $header: &;


  &-domain_select {
    position: absolute;
    top: calc_fluid(134,162,1170,1920);
    @include _1170 {
      top: 120px;
    }
    width: 100%;
    height: calc(100vh - 134px);
    opacity: 0;
    transition: opacity .3s;
    z-index: -1;
    padding: 50px 75px;
    overflow-y: scroll;
    @include _1170 {
      position: fixed;
      top: 0;
      height: 100vh;
    }
    &-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-search {
      position: relative;
      border: 1px solid $borderE2;
      border-radius: 180px;
      height: 68px;
      @include _1170 {
        height: 52px;
        @include _979 {
          height: 42px;
        }
      }
      width: 674px;
      @include _979 {
        width: calc(100% - 60px);
      }
      &-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__icon {
          width: 24px;
          height: 24px;
          fill: $inactiveRed;
        }
        $hds: &;
        &:focus {
          #{$hds}__icon {
            fill: $red2;
          }
        }
      }
      &__input {
        border: none;
        appearance: none;
        font-size: 16px;
        line-height: 24px;
        border-radius: 180px;
        color: $blk;
        padding: 0 64px 0 20px;
        height: 100%;
        width: 100%;
        &::placeholder {
          font-size: 16px;
          line-height: 24px;
          color: #878787;
        }
      }
    }
    &-domains {
      margin-top: 36px;
      columns: 7;
      @include _1920 {
        columns: 5;
        @include _1600 {
          columns: 4;
          @include _979 {
            columns: 3;
            @include _768 {
              columns: 2;
              @include _600 {
                columns: 1;
              }
            }
          }
        }
      }
      &-item {
        display: flex;
        break-inside: avoid-column;
        margin-top: -5.5px;
        &-domain {
          padding: 12.5px;
          font-weight: 500;
          font-size: 16px;
          display: block;
          line-height: 24px;
          color: $blk;
          &:hover {
            color: $red;
            text-decoration: underline;
          }
          &.hidden {
            display: none;
          }
        }
        &__letter {
          margin-top: 5.5px;
          min-height: 37px;
          min-width: 37px;
          height: 37px;
          width: 37px;
          font-weight: 900;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          border: 1px solid $borderE2;
          color: $text;
          margin-right: 19px;
        }
        &.hidden {
          display: none;
        }
      }
    }
    &__close {
      cursor: pointer;
      height: 40px;
      width: 40px;
      fill: $inactiveRed;
      @include _979 {
        height: 36px;
        width: 36px;
      }
    }
  }
  &.domain-select {
    height: 150px;
    #{$header} {
      &-domain_select {
        background: #fff;
        opacity: 1;
        z-index: 10;
      }
      &-desktop-bottom {
        display: none;
      }
      @include _1170 {
        &-domain_select {
          z-index: 12;
          padding: 25px 40px;
        }
        @include _768 {
          padding: 15px 20px;
        }
      }
    }
  }
  &-wrapper {
    width:100%;
  }
  &-mobile {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 11;
    &-top {
      height: 64px;
      border-bottom: 1px solid $borderE2;
      position: relative;
      background:#fff;
      &-menu {
        height:100%;
        width: 60px;
        display:flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 4px;
        &__icon {
          height:24px;
          width:24px;
          fill: $inactiveRed;
          &:first-child {
            display: flex;
          }
          &:last-child {
            display: none;
          }
        }
        $hmm: &;
        &.active {
          #{$hmm}__icon {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: flex;
            }
          }
        }
      }
    }
    &-bottom {
      height:56px;
      display: flex;
      align-items: center;
      padding: 8px 15px;
      justify-content: space-between;
      background:#fff;
    }
    &-menu {
      position: fixed;
      left: -200%;
      top: 121px;
      background:#fff;
      height:calc(100vh - 121px);
      width: 100%;
      z-index: 10;
      transition: left .3s ease-in-out;
      overflow-y: scroll;

      &-top {
        height: 54px;
        width: 100%;
        padding: 0 15px;
        display: flex;
        background: $red2;
        align-items: center;
        @include font(16,24,700,$white);
      }
      &-bg {
        position: fixed;
        left:0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, .37);
        transition: opacity .2s;
        z-index: -2;
        opacity:0;
        &.active {
          z-index: 9;
          opacity: 1;
        }
      }
      &.active {
        left: 0;
      }
      &-catalog {
        width: 100%;

        &-children {
          position:fixed;
          z-index:10;
          left: -200%;
          top: 120px;
          background:#fff;
          height:calc(100vh - 121px);
          width: 100%;
          overflow: auto;
          transition: left .3s ease-in-out;
          
          &-back {
            display: flex;
            padding: 15px;
            align-items: center;
            height:54px;
            border-top: 1px solid $borderE2;
            border-bottom: 1px solid $borderE2;
            background: $inactiveBg;
            &__text {
              font-size: 16px;
              line-height: 24px;
              color: $text;
            }
            &__icon {
              margin-right: 7px;
              width: 12px;
              height: 12px;
              fill: $text;
              transform: rotate(90deg);
            }
          }
          &-item {
            padding: 15px;
            @include font(16,24);
            border-bottom: 1px dashed $borderE2;
            display: block;

            &:last-child{
              margin-bottom:200px;
            }
          }
        }
        $hcatalog: &;
        &-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $borderE2;
          width:100%;
          &__text {
            @include font(18,24,700);
            min-width: fit-content;
            max-width: calc(100% - 36px);
            padding: 15px 0 15px 15px;
          }
          &__subitems_btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: end;
            width: 100%;
            padding: 15px;
          }
          &__icon {
            width: 12px;
            height: 12px;
            margin-left: 20px;
            transform: rotate(-90deg);
            fill: $text;
          }
          &.active {
            & + #{$hcatalog}-children {
              left: 0;
            }
          }
          &-wrapper{
            display: grid;
            grid-template-columns: 55% 45%;
          }
      
        }
      }
    }
    &-logo {
      position: absolute;
      top: 0;
      left: calc(50% - 100px);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__svg {
        height: 17.9px;
        width: 184.5px;
      }
      &__text {
        margin-left: calc_fluid(12, 21.5, 320, 768);
        font-weight: 500;
        font-size: calc_fluid(11,14.6,320,768);
        color: $blk;
        line-height: calc_fluid(14,18.4, 320,768);
      }
    }
    &-phone {
      font-size: 14px;
      line-height: 20px;
      color: $text;
      display:flex;
      align-items: center;
      height:100%;
      margin-left: 15%;
      @include _768 {
        margin-left: 0;
      }
      &__icon {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        fill: $inactiveRed;
      }
      $hmp: &;
      &:hover {
        color: $red2;
        #{$hmp}__icon {
          fill: $red2;
        }
      }
    }
    &__callback {
      width: calc_fluid(137, 600, 320, 1170);
      border-radius: 180px;
      background-color: #5A4C66;
      color:white;
      @include _768 {
        width: calc(100% - 153px);
      }
      height: 37px;
      @include font_btn(12,15,700);
    }
  }
}