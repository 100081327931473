.base {
  &-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &-flex {
    display: flex;
  }
  &-ai_c {
    align-items: center;
  }
  &-jc-sb {
    justify-content: space-between;
  }
  
  &-spacing{
    margin-bottom: clampFluid(100);
  }
  &-links {
    display: flex;
    margin-right: clampFluid(100);
    a{
      display: flex;
      align-items: center;

    }
    
    &__item {
      height: 36px;
      width: 36px;
      margin-right: 10px;
    }
    &__drive {
      width: 88px;
      object-fit: cover;
      border-radius: 5px;
    }
    @include _1600 {
      &__item {
        height: 30px;
        width: 30px;
      }
      &__drive {
        width: 73.3px;
      }
      @include _1320 {
    
        &__drive {
          width: 58.7px;
        }
      }
      @include _979 {
        margin-left:auto;
        margin-right:15px;
      }
    }
  }
  &__title {
    @include font(42,48,700);
    text-align: center;
    width: 100%;
    @include _1440 {
      @include font_btn(28,34);
    }
    @include _979 {
      @include font_btn(22,26);
    }
  }
  &-container {
    width: 1620px;
    @include _1700 {
      width: 1440px;
      @include _1500 {
        width: 1290px;
        @include _1320 {
          width: 1170px;
          padding: 0 15px;
          @include _1170 {
            width: 100%;
          }
        }
      }
    }
    &--center{
      margin: 0 auto;
    }
  }
}

.container{
  width: 1620px;
  margin: 0 auto;
  @include _1700 {
    width: 1440px;
  }
  @include _1500 {
    width: 1290px;
  }
  @include _1320 {
    width: 1170px;
    padding: 0 15px;
  }
  @include _1170 {
    width: 100%;
  }

}



.theme-input{
  &--radio{
      cursor: pointer;
      display: grid;
      align-items: center;
      grid-template-columns: 26px 1fr;
      column-gap: 12px;
      input{
          display: none;
          &:checked{
              & ~ p{
                  &::after{
                      background-color: #C92323;
                  }
              }
          }
      }
      p{
          border: 1px solid #5A4C66;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 26px;
          height: 26px;
          position: relative;
          &::after{
              content: '';
              position: absolute;
              display: block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: transparent;
              transition: all .3s ease;
          }
      }
      span{

      }
  }
}

h1.base__title {
  @include font_btn(48, 55);
  @include _1440 {
    @include font_btn(32,37);
  }
  @include _979 {
    @include font_btn(24,28);
  }
}
