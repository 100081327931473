@import "../../env";

.cookie {
  position: fixed;
  &.hidden {
    display: none;
  }
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $inactiveRed;
  display: flex;
  justify-content: space-between;
  padding: 16px calc_fluid(15,160,320,1920);
  align-items: center;
  @include _1170 {
    flex-flow: row wrap;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding-right: 20px;
    @include _1170 {
      padding: 0;
      width: 100%;
    }
  }
  &__link {
    text-decoration: underline;
  }
  &__btn {
    width: 306px;
    height: 53px;
    border: 1px solid #fff;
    background: $inactiveRed;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: background-color .3s, color .3s;
    color: #fff;
    &:hover {
      background: #fff;
      color: $inactiveRed;
    }
    @include _1170 {
      margin-top: 12px;
      width: 100%;
    }
  }
}