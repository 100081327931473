@import "../general";
@import "../mixins";
@import "../media";
@import "../components/base/base";
@import "../components/base/buttons";
@import "../components/base/header";
@import "../components/base/footer";
@import "../components/modals/base";
@import "../components/base/scroll-top";
@import "../components/base/scroll";
@import "../components/base/cookie";
@import "../components/address-modal";

.phoneMask {
  display:flex;
  height:calc(100vh - 150px);
  width:100vw;
  align-items:center;
  justify-content: center;
  &__input {
    height:50px;
    padding-left: 20px;
    font-size:18px;
    width:200px;
    background:#fcfcfc;
    border: 1px solid;
    border-radius:2px;
  }
}

.--desktop {
  @include _1170 {
    display: none !important;
  }
}

.--mobile {
   @media (min-width: 1170px) {
    display: none !important;
  }
}

.main {
  margin-top: 0;
  transition: margin-top .2s;
  overflow-x: clip;
  &.sticky {
    margin-top: 100px;
  }
  @include _1170 {
    margin-top: 120px;
  }
}

.privacy {
  padding-bottom: 100px;
}
iframe .badge-widget {
  width: 100%;
  height: 100%;
}

