@import "fonts";
@import "variables";

html {
  overflow-x: hidden;
  &.active {
    overflow: hidden;
  }
}

input {
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  padding:0;
  background: #fff;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
}

a {
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
  transition: color .3s;
  &__icon {
    transition: fill .3s;
  }
  &__text {
    transition: color .3s;
  }
}

