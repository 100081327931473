.modal-typical {
  width: 544px;
  position: relative;
  align-items: center;
  @include _600 {
    width: 100%;
    overflow: scroll;
  }
  &-close {
    position: absolute;
    top: 19px;
    cursor: pointer;
    right: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    &__svg {
      width: 24px;
      height: 24px;
      fill: $blk;
    }
  }
  &-form {
    display: block;
    margin-top: 20px;
    width: 100%;
    & > .form-group {
      margin-top: 12px;
      & > input {
        border: 1px solid $borderE2;
      }
    }
  }
  &__header {
    @include font(30,36,700);
    @include _1600 {
      @include font_btn(26,30);
      @include _1170 {
        @include font_btn(20,26);
      }
    }
    text-align: center;
  }
  &__description {
    margin-top: 12px;
    @include font_btn(20,26);
    @include _1170 {
      @include font_btn(18,20);
      @include _600 {
        @include font(15,18);
      }
    }
    text-align: center;
  }
  &__send {
    margin-top: 20px;
    width: 100%;
  }
  &-personal {
    margin-top: 8px;
    text-align: center;
    max-width: 294px;
    &, &__link {
      @include font(18,25,null,$inactive87);
      @include _1170 {
        @include font_btn(16,20);
      }
    }
    &__link {
      color: $inactiveText;
      text-decoration: underline;
    }
  }
  &__error {
    display: none;
    margin-top: 12px;
    font-size: calc_fluid(12,16,320,1920);
    line-height: calc_fluid(18,24,320,1920);
    color: $red;
    &.active {
      display: block;
    }
  }
}