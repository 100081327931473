@import './domains';
@import "./near";
@import "./typical";
@import "./response";

.modal {
  &-bg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, .54);
    display: none;
    justify-content: center;
    align-items: center;
    &.active {
      display: flex;
    }
    z-index: 20;
  }
  &-base {
    background: #fff;
    border-radius: 30px;
    padding: 50px 30px;
    display: none;
    &.active {
      display: flex;
      flex-direction: column;
    }
  }
}

