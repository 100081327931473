.footer {
  width: 100%;
  background: $text;
  &-phone {
    margin-left: 13px;
    &__icon {
      height: 24px;
      width: 24px;
      fill: $white;
      margin-right: 10px;
      @include _1600 {
        height: 16px;
        width: 16px;
        margin-right: 7px;
      }
    }
    display:flex;
    align-items: center;
    @include font(22,30,bold,$white);
    @include _1600 {
      @include font_btn(18,24,null,);
      @include _1320 {
        @include font_btn(14,20,null);
        @include _480 {
          font-weight: 400;
        }
      }

    }
    $hdp: &;
    &:hover {
      #{$hdp}__icon {
        fill: $red2;
      }
      color: $red2;
    }
    @include _768 {
      margin-left: 0;
    }
  }
  &__callback {
    width: 255px;
    height: 68px;
    margin-left: 32px;
    @include font_btn(20,30,700);
    @include _1600 {
      width: 240px;
      height: #{calc_static(271,240,68)}px;
      @include font_btn(18,24,700);
      @include _1320 {
        width: 170px;
        height: #{calc_static(271,170,68)}px;
        @include font_btn(14,22,700);
        @include _480 {
          width: 149px;
          margin-left: 0;
          height: #{calc_static(271,149,68)}px;
          @include font_btn(12,15,700);
        }
      }
    }
    &_register {
      width: 271px;
      margin-left: 27px;
      @include _1320 {
        width: 181px;
        height: #{calc_static(271,181,68)}px;
        @include font_btn(14,22,700);
      }
      @include _1080 {
        width: 50%;
        margin-left: 15px;
      }
      @include _768 {
        margin-left: 0;
        padding: 0 15px;
        margin-top: 10px;
        width: calc(100% - 30px);
        @include _480 {
          @include font_btn(13,19);
        }
      }
    }


  }
  &__feedback {
    display: flex;
    @include _768 {
      width: 100%;
      padding: 0 15px;
      justify-content: space-around;
      @include _480 {
        justify-content: space-between;
      }
    }
    //@include _600 {
    //  @include _480 {
    //    width: 100%;
    //    padding: 0 15px;
    //    flex-direction: column;
    //  }
    //}
  }
  &__links {
    margin-top: 26.58px;
    @include _1320 {
      margin-top: 22px;
    }
    @include _979 {
      margin-top: 0;
      margin-left: 20px;
      @include _480 {
        margin-top: 20px;
        width: fit-content;
        margin-left: 0;
        & .base-links__item {
          width: 36px;
          height: 36px;
        }
        & .base-links__drive {
          width: 88px;
          height: 36px;
        }
      }
    }
  }
  &-search {
    width: 250px;
    position: relative;
    height: 54px;
    border-bottom: 2px solid $border5F;
    &__input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background: transparent;
      &, &::placeholder {
        @include font(20,30,null,$white);
        @include _1320 {
          @include font_btn(14,20);
        }
      }
    }
    &-btn {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      border: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      width: 54px;
      &__icon {
        width: 24px;
        height: 24px;
        fill: $white;
      }
      $fsb: &;
      &:focus {
        #{$fsb} {
          &__svg {
            fill: $red2;
          }
        }
      }
    }
    @include _1320 {
      height: 32px;
      min-height: 32px;
      width: 167px;
      &-btn {
        width: 32px;
        &__icon {
          width: 16px;
          height: 16px;
        }
      }
      @include _979 {
        flex: 1;
        width: unset;
        @include _480 {
          width: 100%;
        }
      }
    }
  }
  &-logo {
    &__icon {
      height: calc_fluid(24.17,36.22,1280,1920);
      width: calc_fluid(247.24, 370.58, 1280, 1920);
      @include _979 {
        width: 227px;
        height: 22.26px;
      }
    }
    &__text {
      @include font(22,30,null,$white);
      @include _1600 {
        @include font_btn(13,19);
        @include _979 {
          @include font_btn(13,18);
        }
      }
    }
  }
  &-top {
    width: 100%;
    display: flex;
    align-items: center;
    height: 135px;
    border-bottom: 1px solid $border46;
    @include _1080 {
      height: unset;
    }
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include _1080 {
        padding: 20px 0;
        flex-direction: column;
        justify-content: unset;
        @include _600 {
          padding: 40px 0 14px;
        }
      }
    }
    &__content {
      display: flex;
      height: 100%;
      align-items: center;
      @include _1080 {
        margin-top: 20px;
        width: 100%;
        justify-content: space-around;
      }
      //@include _979 {
      //  flex-direction: column;
      //  align-items: flex-end;
      //}
      @include _768 {
        margin-top: 30px;
        padding-top: 14px;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        border-top: 1px solid $border46;
        width: 100%;
      }
    }
  }
  &-middle {
    display: flex;
    justify-content: space-between;
    padding: 25px 0 15px;
    &__left {
      @include _979 {
        display: flex;
        margin-top: 35px;
        @include _480 {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    &__inner {
      display: flex;
      @include _979 {
        flex-direction: column-reverse;
      }
    }
    &-nav {
      display: flex;
      justify-content: space-between;
      flex: 1;
      margin-left: 79px;
      &__title {
        @include font(27.5,33,700,$white);
        margin-bottom: 14.25px;
        display: block;
      }
      &__link {
        @include font(20,30,null,$white);
        max-width: 288px;
        padding: 8.25px 0;
        display: block;
      }
      @include _1600 {
        margin-left: 52px;
        &__title {
          @include font_btn(24,22);
          margin-bottom: 9px;
        }
        &__link {
          @include font_btn(16,20);
          padding: 6.5px 0;
          max-width: 192px;
        }
        @include _1320 {
          margin-left: 52px;
          &__title {
            @include font_btn(16,22);
            margin-bottom: 8px;
          }
          &__link {
            @include font_btn(14,20);
            padding: 5px 0;
            max-width: 192px;
          }
        }
      }
      @include _979 {
        margin-left: 0;
        flex-wrap: wrap;
        &-category {
          margin-top: 20px;
          width: calc(100% / 3 - 30px);
          @include _768 {
            width: calc(100% / 2 - 30px);
            @include _600 {
              width: 100%;
            }
          }
        }
      }
    }
    @include _979 {
      padding: 10px 0 15px;
    }
    @include _600 {
      padding: 10px 0 15px !important;
    }
  }
  &-bottom {
    border-top: 1px solid $border46;
    padding: 16px 0;
    &__inner {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    &__copyright {
      margin-right: 43px;
      padding: 2px 0;
      @include _768 {
        width: 100%;
        margin-right: 0;
      }
    }
    &__copyright, &__privacy {
      @include font(18,28,null,$footerBottomText);
      @include _1320 {
        @include font_btn(13,19);
        @include _480 {

        }
      }
    }
    &__privacy {
      margin-right: 37px;
      &:hover {
        color: $white;
      }
    }
    &-placestart {
      display: flex;
      align-items: center;
      @include font(18,20,null,$footerBottomText);
      @include _1320 {
        @include font_btn(13,19);
      }
      $fbps:&;
      &__logo {
        height: 24px;
        width: 30px;
        fill: #9BBF2E;
        margin-left: 10.5px;
        margin-top: 2px;
      }
      &__text {
        height: 14.77px;
        width: 118.6px;
        fill: $footerBottomText;
      }
      &__logo, &__text {
        transition: fill .2s;
      }
      &:hover {
        color: $white;
        #{$fbps} {
          &__logo, &__text {
            fill: $white;
          }
        }
      }
    }
    @include _480 {
      padding: 10px 0 25px;
      &__copyright, &__privacy, &-placestart {
        margin-top: 20px;
        margin-right: 0;
        width: fit-content;
      }
      &__inner {
        flex-direction: column;
        justify-content: unset;
        align-items: center;
      }
    }
  }
  &-ps {
    display: flex;
    align-items: center;
    &__logo {
      height: 30px;
      width: 246px;
    }
  }
  &-nav {
    width: calc(100% - 412px);
    @include _1800 {
      width: 100%;
    }
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @include _768 {
      padding-right: calc_fluid(0,177, 600, 768);
    }
    &-item {
      width: calc(100% / 4);
      @include _1320 {
        margin-top: 30px;
        width: calc(100% / 2);
        @include _768 {
          width: calc(50% - 50px);
          @include _600 {
            width: 100%;
          }
        }
      }
      &__title {
        display: block;
        font-weight: bold;
        font-size: calc_fluid(18, 22, 320, 1920);
        line-height: calc_fluid(20, 26, 320, 1920);
        color: $blk;
        &:hover {
          color: $red;
        }
      }
      &-subitem {
        display: block;
        padding: calc_fluid(6, 8.5, 320,1920) 0;
        color: $blk;
        font-size: calc_fluid(14,15,320,1920);
        line-height: 20px;
        &:hover {
          color: $red;
        }
        &s {
          max-width: 210px;
          margin-top: calc_fluid(8, 11.5, 320, 1920);
        }
      }
    }
  }
  &-info {
    width: 412px;
    position: relative;
    &__link {
      font-weight: bold;
      font-size: calc_fluid(18, 22, 320, 1920);
      line-height: calc_fluid(20, 26, 320, 1920);
      color: $blk;
      margin-top: 20px;
      margin-right: 20px;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        color: $red;
      }
    }
    &-drive2 {
      margin-top: 34px;
      margin-right: 34px;
      height: 46px;
      width: 112px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-drive2, &__link {
      display: block;
    }
    &-social {
      margin-top: 34.86px;
      margin-right: 34.86px;
      display: flex;
      width: 283.14px;
      @include _480 {
        margin-left: 10.48px;
        width: 250.23px;
      }
      justify-content: space-between;
      &-item {
        height: calc_fluid(37.81, 43.83, 320, 1920);
        width: calc_fluid(37.81, 43.83, 320, 1920);
        &__icon {
          height: 100%;
          width: 100%;
          fill: none;
          object-fit: cover;
        }
      }
    }
    &-payment {
      margin-top: 36.31px;
      @include _768 {
        margin-top: 25.71px;
      }
      &__title {
        font-size: 15px;
        line-height: 26px;
        color: $blk;
      }
      &-items {
        margin-top: 18px;
        display: flex;
      }
      &-item {
        height: 11px;
        object-fit: contain;
        margin-right: 20px;
      }
    }
    @include _1800 {
      &-left {
        width: 100%;
        display: flex;
        justify-content: space-around;
        @include _768 {
          display: block;
        }
      }
      &-right {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-around;
        margin-top: 20px;
        @include _768 {
          margin-top: 40px;
          display: block;
        }
        &-left {
          display: flex;
          align-items: baseline;
          @include _600 {
            display: block;
          }
        }
      }
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;
      &__link, &-drive2, &-social, &-payment {
        margin-top: 0;
      }
      @include _768 {
        &-payment {
          margin-top: 40px;
        }
        @include _600 {
          &-social {
            margin-top: 30px;
          }
          &-payment {
            margin-top: 25.5px;
          }
        }
        &__link {
          margin-top: 14px;
        }
      }
    }
  }

  &-registration {
    height: calc_fluid(40,53,320,1920);
    width: calc_fluid(176,243,320,1920);
    font-weight: 900;
    font-size: calc_fluid(11,16,320,1920);
    line-height: calc_fluid(15,24,320,1920);
    @include _1170 {
      width: 50%;
      @include _979 {
        width: 100%;
        @include _600 {
          width: 176px;
        }
      }
    }
    @include _600 {
      margin: 15px 0 0 0;
    }
    &.--meduim {
      display: none;
      @include _1170 {
        display: flex;
      }
      @include _600 {
        display: none;
      }
    }
    &.--dm {
      display: flex;
      @include _1170 {
        display: none;
        @include _600 {
          display: flex;
        }
      }
    }
  }
  @include _600 {
    padding: 0;
    &-top {
      padding: 0;
      &-right {
        padding: 26px 15px 28px;
      }
    }
    &-middle {
      padding: 0 15px 36.77px;
    }
  }
}