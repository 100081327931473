.modal {
  &-response {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 466px;
  }
  &-success {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    &__icon {
      width: 65px;
      height: 65px;
      fill: none;
    }
    &__header {
      margin-top: 30px;
      max-width: 328px;
    }
    &__description {
      margin-top: 20px;
    }
  }
  &-error {
    &__icon {
      width: 65px;
      height: 65px;
      fill: none;
    }
    &__header {
      margin-top: 30px;
      max-width: 328px;
    }
    &__description {
      margin-top: 20px;
    }
  }
  &-load {
    &__icon {
      width: 90px;
      height: 90px;
      fill: none;
      & .tire {
        animation: rotation 1800ms infinite linear;
        transform-origin: 50% 50%;
      }
      & .rim {
        animation: rotation 1200ms infinite linear;
        transform-origin: 50% 50%;
      }
    }
    &__header {
      margin-top: 21px;
    }
  }
}


@keyframes rotation {
  from {transform: rotate(0deg);}
  to   {transform: rotate(360deg);}
}