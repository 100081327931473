.modal-near {
  padding: calc_fluid(20,31,320,1920) calc_fluid(20,45,320,1920) calc_fluid(20,48,320,1920) calc_fluid(20,36,320,1920);
  width: 1215px;
  height: 718px;
  @include _1290 {
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }
  &__header {
    font-weight: 900;
    font-size: calc_fluid(18,28,320,1920);
    line-height: calc_fluid(24,36,320,1920);
    @include _1170 {
      padding-right: 20px;
    }
    color: $blk;
  }
  &-close {
    display: none;
    position: absolute;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 0;
    right: 0;
    &__svg {
      width: 24px;
      height: 24px;
      fill: $blk;
    }
    @include _1290 {
      display: flex;
    }
  }
  &-content {
    width: 100%;
    height: -webkit-fill-available;
    position: relative;
    margin-top: 26px;
    &-select {
      position: relative;
      z-index: 1;
      padding: 40px 32px;
      height: 486px;
      width: 496px;
      box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      background: #fff;
      @include _1170 {
        width: 100%;
        height: 400px;
      }
      &-list {
        width: 100%;
        height: 345px;
        @include _1170 {
          height: 250px;
        }
        margin-top: 20px;
        &-item {
          display: flex;
          &.hidden {
            display: none;
          }
          &-letter {
            width: 38px;
            height: 38px;
            border: 1px solid $red;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
            color: $red;
            margin-right: 19px;
          }
          &-subitems {
            display: block;
            margin-top: -5.5px;
            &__city {
              font-weight: 500;
              padding: 12.5px 0;
              display: block;
              font-size: 16px;
              line-height: 24px;
              color: $blk;
              &.active {
                text-decoration-line: underline;
                color: $red;
              }
              &:hover {
                color: $blu;
              }
              &.hidden {
                display: none;
              }
            }
          }
        }
      }
      &-map {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include _1170 {
          margin-top: 15px;
          position: unset;
        }
      }
    }
  }
}